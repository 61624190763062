import { useRouter } from 'next/navigation';
import { useCallback, useMemo } from 'react';

import { getMachineFromParams } from '../utils/MachineUtils';
import { useUrlParams } from '@lib/machine-parts/storefront/utils';

export interface Machine {
    model: string;
    brand: string;
    year?: string;
}

export type ParsedMachine = Omit<Machine, 'year'> & { year?: number };

export interface UseMachineParamsResult {
    setMachine: (m: Machine) => void;
    unsetMachine: () => void;
    machine?: ParsedMachine;
    isMachineSet: boolean;
}

export function useMachineParams(): UseMachineParamsResult {
    const { buildUrl, searchParams } = useUrlParams();
    const { replace: navigate } = useRouter();

    const machine = useMemo(() => getMachineFromParams(searchParams), [searchParams]);

    const setMachine = useCallback(
        (input: Machine) => {
            const machine = { model: input.model, brand: input.brand, year: input.year };
            navigate(buildUrl({ params: machine }).url);
        },
        [buildUrl, navigate],
    );

    const unsetMachine = useCallback(() => {
        navigate(buildUrl({ params: { model: null, brand: null, year: null } }).url);
    }, [buildUrl, navigate]);

    const isMachineSet = useMemo(() => machine !== undefined, [machine]);

    return {
        setMachine,
        unsetMachine,
        machine,
        isMachineSet,
    };
}
