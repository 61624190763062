'use client';

import { useCallback } from 'react';
import { Path, UseFormReturn } from 'react-hook-form';
import { formatTranslation, yup } from '@lib/machine-parts/storefront/utils';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormModal, getModalContent, Input } from '@lib/machine-parts/storefront/ui';
import { MissingMachineEmailInput } from '../../../../app/api/mail/missing-machine-brand/missing-machine-brand';
import { MachineSelectorSanityData } from '../../../external/sanity/settings/getMachineSelectorSanityData';
import { useSubscribeMutation } from '../../../hooks/api/subscribe-mutation/useSubscribeMutation';

const SUBSCRIBE_URL = '/api/mail/missing-machine-brand';
type NotificationForm = Omit<MissingMachineEmailInput, 'brand' | 'model'>;
export const yupSchema = yup.object().shape({
    emailOrPhone: yup.string().required(),
    description: yup.string().required(),
    comment: yup.string().optional(),
});

interface InputProps {
    formMethods: Pick<UseFormReturn<NotificationForm>, 'formState' | 'register'>;
    name: Path<NotificationForm>;
    required?: boolean;
}

export enum MissingMachineModalType {
    BRAND = 'brand',
    MODEL = 'model',
}

export function MissingMachineBrandPopup({
    onClose,
    type,
    translation,
    data: staticData,
}: {
    open?: boolean;
    onClose: () => void;
    type: MissingMachineModalType;
    translation?: MachineSelectorSanityData['modal'];
    data: Omit<MissingMachineEmailInput, 'emailOrPhone' | 'description' | 'comment'>;
}) {
    const t = formatTranslation(translation);
    const { subscribe, status, reset } = useSubscribeMutation();

    const inputFields: Omit<InputProps, 'formMethods'>[] = [
        { name: 'emailOrPhone', required: true },
        { name: 'description', required: true },
        { name: 'comment' },
    ];

    const getInput = useCallback(
        ({ formMethods: { formState, register }, name, required }: InputProps) => (
            <div key={name}>
                <label htmlFor={`modal-form-${name}`} className="block text-sm font-medium text-gray-700">
                    {t(`form.${name}_label`)}
                </label>
                <Input
                    id={`modal-form-${name}`}
                    testId={`${name}Input`}
                    key={`modal-form-${name}`}
                    placeholder={t(`form.${name}_placeholder`, { allowUndefined: true })}
                    variant="secondary"
                    {...register(name)}
                    error={formState.errors[name]?.message as string}
                    valid={!formState.errors[name]?.message && (!required || formState.dirtyFields[name])}
                />
            </div>
        ),
        [t],
    );

    return (
        <FormModal<NotificationForm>
            title={t(`${type}.title`)}
            onSubmit={async (data: NotificationForm) => await subscribe(SUBSCRIBE_URL, { ...staticData, ...data })}
            onClose={() => {
                onClose();
                reset();
            }}
            status={status}
            customOnClick
            defaultValues={{ emailOrPhone: '', description: '' }}
            resolver={yupResolver(yupSchema)}
            translation={translation?.form}
        >
            {({ formState, register }) =>
                getModalContent({ status, translation: translation?.form.text }) ?? (
                    <div className="flex flex-col gap-2">
                        {inputFields.map((props) => getInput({ ...props, formMethods: { formState, register } }))}
                    </div>
                )
            }
        </FormModal>
    );
}

export default MissingMachineBrandPopup;
