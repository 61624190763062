'use client';

import { useMemo } from 'react';
import { ParsedMachine, useMachineParams } from '../../../hooks/useMachineParams';
import { MachineDisplay } from './MachineDisplay';
import { MachineSelector, MachineSelectorProps } from './MachineSelector';

export function MachineHeader(props: MachineSelectorProps) {
    const { machine, isMachineSet, unsetMachine } = useMachineParams();
    const translatedMachine: ParsedMachine | undefined = useMemo(() => {
        if (!machine?.brand || !machine.model) return undefined;
        const brand = props.machines?.find(({ slug }) => slug === machine.brand);
        const model = brand?.models?.find(({ slug }) => slug === machine.model);
        return { brand: brand?.name ?? machine.brand, model: model?.name ?? machine.model, year: machine.year };
    }, [machine, props.machines]);

    return (
        <div className="flex bg-brand-background items-center h-16" data-testid="machine-selector">
            {isMachineSet && translatedMachine ? (
                <MachineDisplay
                    unsetMachine={unsetMachine}
                    selectedMachine={translatedMachine}
                    translation={props.translation?.machine_selector}
                />
            ) : (
                <MachineSelector {...props} />
            )}
        </div>
    );
}
