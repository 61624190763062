'use client';

import { useRouter } from 'next/navigation';
import { useCallback, useMemo, useState } from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';
import { Button, ListSelect, Tooltip } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { formatTranslation, useMixpanel, useUrlParams } from '@lib/machine-parts/storefront/utils';

import { MachineSelectorSanityData } from '../../../external/sanity/settings/getMachineSelectorSanityData';
import { getMachineSelectorOptions, UNSURE_OPTION_VALUE } from '../../../utils/MachineUtils';
import { MissingMachineBrandPopup as MissingMachineBrand, MissingMachineModalType } from './MissingMachineBrand';

export interface MachineSelectorProps {
    machines?: MachineSelectorSanityData['machine_selector']['brands'];
    translation?: MachineSelectorSanityData;
}

export function MachineSelector({ machines, translation }: MachineSelectorProps) {
    const mixpanel = useMixpanel();
    const t = formatTranslation(translation?.machine_selector);

    const [missingMachine, setMissingMachine] = useState<
        { type: MissingMachineModalType; brand?: string; model?: string } | undefined
    >(undefined);
    const { params, buildUrl } = useUrlParams();
    const { replace: navigate } = useRouter();

    const [brand, setBrand] = useState(params.brand);
    useMemo(() => setBrand(params.brand), [params.brand]);
    const [model, setModel] = useState(params.model);
    useMemo(() => setModel(params.model), [params.model]);
    const [year, setYear] = useState(params.year);
    useMemo(() => setYear(params.year), [params.year]);

    const { brandOptions, modelOptions, yearOptions } = useMemo(
        () => getMachineSelectorOptions({ brands: machines, brand, model }),
        [brand, machines, model],
    );

    const handleBrandChange = useCallback(
        (brand: string) => {
            mixpanel.track(`Machine Selector Brand Select`, { brand });
            if (!brandOptions.find(({ value }) => value === brand)?.enabled) {
                setMissingMachine({ type: MissingMachineModalType.BRAND, brand, model });
            } else {
                setBrand(brand);
                navigate(buildUrl({ params: { brand, model: null, year: null } }).url);
            }
        },
        [mixpanel, brandOptions, model, buildUrl, navigate],
    );

    const handleModelChange = useCallback(
        (model: string) => {
            if (!modelOptions.find(({ value }) => value === model)?.enabled) {
                setMissingMachine({ type: MissingMachineModalType.MODEL, brand, model });
                return;
            }
            setModel(model);
            navigate(buildUrl({ params: { brand, model, year: null } }).url);
        },
        [modelOptions, buildUrl, brand, navigate],
    );

    const handleYearChange = useCallback(
        (year: string) => {
            setYear(year);
            navigate(buildUrl({ params: { brand, model, year } }).url);
            mixpanel.track(`Machine Selector Set Machine Button`, { machine: { brand, model, year } });
        },
        [buildUrl, brand, model, navigate, mixpanel],
    );

    return (
        <div className={`flex flex-1 items-center justify-between px-0 lg:px-6 2xl:px-20`}>
            <div className={'flex flex-col flex-1 lg:flex-row gap-4 items-center'}>
                {t('prefix', { allowUndefined: true }) && (
                    <p className="mr-4 hidden xl:flex uppercase">{t('prefix')}</p>
                )}
                <Link
                    className={`font-heading-ext color-brand-black text-lg font-semibold h-16 block !w-full lg:hidden uppercase mt-12`}
                    href={buildUrl({ path: '/machine-selector' }).url}
                    mixpanel={{ eventName: `Machine Selector Select Machine Button` }}
                    icon
                >
                    {t('machine_selector_mobile')}
                </Link>
                <div data-testid="brand">
                    <ListSelect
                        id="brand"
                        options={brandOptions}
                        handleChange={handleBrandChange}
                        selected={brand ?? ''}
                        placeholder={t('form.brand_selector_placeholder', { allowUndefined: true })}
                        className={`w-52 h-12`}
                        containerClassName={`hidden lg:block`}
                    />
                </div>
                <div data-testid="model">
                    <ListSelect
                        id="model"
                        placeholder={t('form.model_selector_placeholder', { allowUndefined: true })}
                        options={modelOptions}
                        handleChange={handleModelChange}
                        selected={model ?? ''}
                        disabled={!brand}
                        className={`w-52 h-12`}
                        containerClassName={`hidden lg:block`}
                    />
                </div>
                <div data-testid="year">
                    <ListSelect
                        id="year"
                        placeholder={t('form.year_selector_placeholder', { allowUndefined: true })}
                        options={[{ name: t('form.unknown_option'), value: UNSURE_OPTION_VALUE }, ...yearOptions]}
                        selected={year ?? ''}
                        handleChange={handleYearChange}
                        disabled={!brand || !model}
                        className={`w-52 h-12`}
                        containerClassName={`hidden lg:block`}
                    />
                </div>
                <Button
                    onClick={() => {
                        navigate(
                            buildUrl({
                                path: '/machine-selector/categories/',
                                params: { brand, model, year: UNSURE_OPTION_VALUE },
                            }).url,
                        );
                    }}
                    disabled={!(brand && model)}
                    className="h-16 md:h-12 hidden lg:flex gap-2"
                    icon
                >
                    <span className="hidden xl:flex">{t('form.explore_machine')}</span>
                    <span className="flex xl:hidden">{t('form.explore_machine_mobile')}</span>
                </Button>
            </div>

            <Tooltip
                content={t('tooltip')}
                placement="bottom"
                trigger="hover"
                arrow={true}
                className="hidden lg:flex lg:flex-1 items-center text-white bg-brand-brown shadow-sm rounded-none text-base"
            >
                <button type="button" className={'hidden lg:flex text-left underline items-center'}>
                    <div className={'hidden xl:flex'}>{t('question')}</div>
                    <div className={`flex xl:hidden h-6`}>
                        <InformationCircleIcon className="h-6 text-brand-brown" />
                    </div>
                </button>
            </Tooltip>

            {missingMachine?.type && (
                <MissingMachineBrand
                    onClose={() => setMissingMachine(undefined)}
                    type={missingMachine.type}
                    translation={translation?.modal}
                    data={{
                        brand: missingMachine.brand ?? 'Unknown brand',
                        model: missingMachine.model,
                    }}
                />
            )}
        </div>
    );
}
