'use client';

import axios from 'axios';
import { useState } from 'react';

type SubscribeMutationStatus = 'IDLE' | 'LOADING' | 'SUCCESS' | 'FAILED';
type Resulted = Awaited<ReturnType<typeof postSubscribe>>['data'];

export function useSubscribeMutation<Params = Record<string, string>>() {
    const [data, setData] = useState<Resulted | null>(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState<SubscribeMutationStatus>('IDLE');
    return {
        subscribe: async (url: string, input: Params) => {
            setStatus('LOADING');
            try {
                const result = await postSubscribe(url, input);
                setStatus('SUCCESS');
                setData(result.data);
                setError(null);
                return result;
            } catch (error: any) {
                setStatus('FAILED');
                setData(null);
                setError(error);
                throw error;
            }
        },
        reset: () => {
            setStatus('IDLE');
            setData(null);
            setError(null);
        },
        isLoading: status === 'LOADING',
        isSuccess: status === 'SUCCESS',
        isFailed: status === 'FAILED',
        status,
        data,
        error,
    };
}

async function postSubscribe<Params = Record<string, string>>(url: string, input: Params) {
    return axios.post<number>(url, input);
}
