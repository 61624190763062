'use client';

import { XCircleIcon } from '@heroicons/react/outline';
import { Button, ButtonVariant, ImageWithFallback } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { formatTranslation, useMixpanel } from '@lib/machine-parts/storefront/utils';

import { MachineSelectorSanityData } from '../../../external/sanity/settings/getMachineSelectorSanityData';
import { ParsedMachine } from '../../../hooks/useMachineParams';
import { findMachineModelImage } from '../../../utils/MachineUtils';
import { MachineSelectionIndicator } from './MachineSelectionIndicator';

export interface MachineDisplayProps {
    selectedMachine: ParsedMachine;
    machines?: MachineSelectorSanityData['machine_selector']['brands'];
    translation?: Omit<MachineSelectorSanityData['machine_selector'], 'brands'>;
    unsetMachine: () => void;
}

export function MachineDisplay({ machines, translation, selectedMachine, unsetMachine }: MachineDisplayProps) {
    const matchedModelImage = findMachineModelImage({ ...selectedMachine, brands: machines });
    const mixpanel = useMixpanel();

    const t = formatTranslation(translation);

    return (
        <div className={'w-full flex justify-between px-0 md:px-6 lg:px-16 2xl:px-20 items-center'}>
            <div className={'md:hidden flex ml-4 gap-2'}>
                <MachineSelectionIndicator machine={selectedMachine} mobile />
                <button onClick={unsetMachine} className={'flex items-center'}>
                    <XCircleIcon className={`h-6 text-brand-brown`} />
                </button>
            </div>

            <div className="flex items-center gap-6 lg:gap-8">
                <button onClick={unsetMachine} className={'hidden md:flex lg:hidden items-center'}>
                    <XCircleIcon className={`h-6 text-brand-brown`} />
                </button>

                {matchedModelImage && (
                    <ImageWithFallback
                        src={matchedModelImage}
                        className={'w-auto h-14 hidden md:block'}
                        alt={'machine-display'}
                        mixpanel={mixpanel}
                        eventInfo={{ location: 'Machine display', title: 'Matched model image' }}
                    />
                )}
                <MachineSelectionIndicator machine={selectedMachine} />

                <Link
                    href="/machine-selector/categories"
                    className={`flex h-16 md:h-12`}
                    mixpanel={{ eventName: 'Machine Selector Show Parts Button' }}
                    icon
                    data-testid="explore-machine"
                >
                    <span className={'hidden sm:block'}>{t('form.explore_machine')}</span>
                    <span className={'block sm:hidden'}>{t('form.explore_machine_mobile')}</span>
                </Link>
            </div>

            <div className="hidden lg:flex">
                <Button
                    className="hidden md:flex uppercase"
                    variant={ButtonVariant.Secondary}
                    onClick={unsetMachine}
                    mixpanel={{ eventName: 'Machine Selector Clear Machine Button' }}
                    icon
                >
                    {t('clear_machine')}
                </Button>
            </div>
        </div>
    );
}
