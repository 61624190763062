export const MachineSelectionIndicator = ({
    machine,
    mobile,
}: {
    machine: { brand: string; model: string; year?: number };
    mobile?: boolean;
}) => {
    return mobile ? (
        <div className={'flex flex-col'}>
            <div className={'text-sm font-normal tracking-widest text-gray-600 uppercase leading-4'}>
                {machine.brand}
            </div>
            <h5 className={'text-lg leading-5'}>{machine.model + ' ' + (machine.year ?? '')}</h5>
        </div>
    ) : (
        <div className={'flex flex-row items-center gap-8'}>
            <div className={'hidden md:block'}>
                <div className="text-sm font-normal tracking-widest text-gray-600 uppercase leading-4">brand</div>
                <h5 className={'text-lg leading-5'}>{machine?.brand}</h5>
            </div>
            <div className={'hidden md:block'}>
                <div className="text-sm font-normal tracking-widest text-gray-600 uppercase leading-4">model</div>
                <h5 className={'text-lg leading-5'}>{machine?.model}</h5>
            </div>
            {machine?.year && (
                <div className={'hidden md:block'}>
                    <div className="text-sm font-normal tracking-widest text-gray-600 uppercase leading-4">year</div>
                    <h5 className={'text-lg leading-5'}>{machine.year}</h5>
                </div>
            )}
        </div>
    );
};
