import { URLSearchParams } from 'url';

import { ListSelectOption } from '@lib/machine-parts/storefront/ui';
import { ProductSearchResult, SearchParam } from '@lib/machine-parts/storefront/utils';

import { ProductSanityData } from '../external/sanity/products/getProductSanityData';
import { MachineSelectorSanityData } from '../external/sanity/settings/getMachineSelectorSanityData';

export enum MachineCompatibilityStatus {
    COMPATIBLE = 'COMPATIBLE',
    NOT_COMPATIBLE = 'NOT_COMPATIBLE',
    PARTIALLY_COMPATIBLE = 'PARTIALLY_COMPATIBLE',
}

export interface CheckMachineCompatibilityProps {
    selectedMachine: { brand: string; model: string; year?: number };
    machines?: ProductSanityData['machineCompatibility'];
    machineCompatibility?: ProductSearchResult['compatibility'];
}

export function checkMachineCompatibility({
    selectedMachine,
    machines,
    machineCompatibility,
}: CheckMachineCompatibilityProps): MachineCompatibilityStatus {
    if (machines?.length) {
        const machine = machines.find(
            ({
                model: {
                    brand: { id: brandId },
                    id: modelId,
                },
                years,
            }) =>
                selectedMachine.brand === brandId &&
                selectedMachine.model === modelId &&
                (!selectedMachine.year || !years?.length || years?.includes(selectedMachine.year)),
        );

        if (!machine) return MachineCompatibilityStatus.NOT_COMPATIBLE;
        return selectedMachine.year
            ? MachineCompatibilityStatus.COMPATIBLE
            : MachineCompatibilityStatus.PARTIALLY_COMPATIBLE;
    }

    if (machineCompatibility) {
        if (!machineCompatibility.brands.includes(selectedMachine.brand)) {
            return MachineCompatibilityStatus.NOT_COMPATIBLE;
        }
        if (selectedMachine.year) {
            return machineCompatibility.modelYears.includes(`${selectedMachine.model};${selectedMachine.year}`)
                ? MachineCompatibilityStatus.COMPATIBLE
                : MachineCompatibilityStatus.NOT_COMPATIBLE;
        }
        return machineCompatibility.models.includes(selectedMachine.model)
            ? MachineCompatibilityStatus.PARTIALLY_COMPATIBLE
            : MachineCompatibilityStatus.NOT_COMPATIBLE;
    }

    return MachineCompatibilityStatus.COMPATIBLE;
}

export interface FindMachineModelImageProps {
    brand?: string;
    model?: string;
    brands?: MachineSelectorSanityData['machine_selector']['brands'];
}

export function findMachineModelImage({ brand: brandId, model: modelId, brands }: FindMachineModelImageProps) {
    if (!brands?.length || !brandId || !modelId) return undefined;

    const brand = brands.find(({ slug }) => slug === brandId);
    if (!brand || !brand.enabled) return undefined;

    const model = brand.models?.find(({ slug }) => slug === modelId);
    return model?.image?.url;
}

export const UNSURE_OPTION_VALUE = 'unsure';

export interface GetMachineSelectorOptionsProps {
    model?: string;
    brand?: string;
    brands?: MachineSelectorSanityData['machine_selector']['brands'];
}

interface GetMachineSelectorOptionsResult {
    brandOptions: (ListSelectOption & { enabled?: boolean })[];
    modelOptions: (ListSelectOption & { enabled?: boolean })[];
    yearOptions: ListSelectOption[];
}

export function getMachineSelectorOptions({
    brand: brandId,
    model: modelId,
    brands,
}: GetMachineSelectorOptionsProps): GetMachineSelectorOptionsResult {
    const brandOptions =
        brands?.map(({ name, slug, logo, enabled }) => ({
            name,
            value: slug,
            image: logo?.url,
            enabled,
        })) ?? [];
    const brand = brands?.find(({ slug }) => slug === brandId);
    if (!brand) return { brandOptions, modelOptions: [], yearOptions: [] };

    const modelOptions =
        brand?.models?.map(({ name, slug, image, enabled }) => ({
            name,
            value: slug,
            image: image?.url,
            enabled,
        })) ?? [];
    const model = brand.models?.find(({ slug }) => slug === modelId);
    if (!model) return { brandOptions, modelOptions, yearOptions: [] };

    const yearOptions = model.years?.map((year) => ({ name: year, value: `${year}` })) ?? [];
    return { brandOptions, modelOptions, yearOptions };
}

export function getMachineFromParams(searchParams?: URLSearchParams) {
    if (!searchParams) return undefined;
    const brand = searchParams.get(SearchParam.BRAND);
    const model = searchParams.get(SearchParam.MODEL);
    const year = searchParams.get(SearchParam.YEAR);
    return brand && model && year
        ? {
              brand,
              model,
              year: year === 'unsure' ? undefined : +year,
          }
        : undefined;
}
