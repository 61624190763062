import { getName } from 'i18n-iso-countries';

import {
    getCountryCallingCode,
    SHIPPABLE_COUNTRIES,
    SHIPPABLE_COUNTRY_FLAGS,
} from '@lib/machine-parts/storefront/utils';

import { ListSelectOption } from '../atoms/ListSelect';

export const SHIPPABLE_COUNTRY_LIST_SELECT_OPTIONS = (language: string): ListSelectOption[] =>
    [...SHIPPABLE_COUNTRIES]
        .sort((a, b) => (getName(a, language) ?? a).localeCompare(getName(b, language) ?? b))
        .map((country) => ({
            name: getName(country, language) ?? country,
            value: country,
            icon: SHIPPABLE_COUNTRY_FLAGS[country],
        }));

export const SHIPPABLE_COUNTRY_CALLING_CODE_LIST_SELECT_OPTIONS: ListSelectOption[] = [...SHIPPABLE_COUNTRIES]
    .sort()
    .map((country) => ({
        name: `${country} (${getCountryCallingCode(country)})`,
        value: getCountryCallingCode(country),
        icon: SHIPPABLE_COUNTRY_FLAGS[country],
    }));
